@import "../../../Assets/Sass/variable.scss";

.mainLine {
  @media (max-width: 426px) {
    width: 100%;
  }
  @media (max-width: 321px) {
    width: 100%;
  }
  .main {
    border-radius: 10px;
    margin-bottom: 80px;
    box-shadow: 3px 2px 20px 0px #00000040;
    .mainfix {
      margin-top: 50px;
      .sethead {
        display: flex;
        align-items: center;
        padding: 0 20px 0 20px;
        justify-content: space-between;
        background-color: #f3f3f3;
        @media (max-width: 321px) {
          padding: 0 10px 0 10px;
        }
        .order {
          font-size: 22px;
          font-weight: 700;
          text-align: start;
          @media (max-width: 426px) {
            font-size: 18px;
          }
        }
        .pend {
          background-color: #fcb711;
          color: $white;
          border-radius: 0;
          width: 12%;
          height: 40px;
          font-weight: 600;
          font-size: 16px;
          @media (max-width: 769px) {
            width: 20% !important;
          }
          @media (max-width: 426px) {
            width: 30% !important;
          }
          @media (max-width: 321px) {
            width: 35% !important;
          }
        }
        .Accepted {
          background-color: #0089d0;
          color: $white;
          border-radius: 0;
          width: 12%;
          height: 40px;
          font-weight: 600;
          font-size: 16px;
          @media (max-width: 769px) {
            width: 20% !important;
          }
          @media (max-width: 426px) {
            width: 50% !important;
          }
        }
        .pickup {
          background-color: #f37021;
          color: $white;
          border-radius: 0;
          width: 12%;
          height: 40px;
          font-weight: 600;
          font-size: 16px;
          @media (max-width: 769px) {
            width: 20% !important;
          }
          @media (max-width: 426px) {
            width: 50% !important;
          }
        }
        .Rejected {
          background-color: #cc004c;
          color: $white;
          border-radius: 0;
          width: 12%;
          height: 40px;
          font-weight: 600;
          font-size: 16px;
          @media (max-width: 769px) {
            width: 20% !important;
          }
          @media (max-width: 426px) {
            width: 50% !important;
          }
        }
        .Delivered {
          background-color: #0db14b;
          color: $white;
          border-radius: 0;
          width: 12%;
          height: 40px;
          font-weight: 600;
          font-size: 16px;
          @media (max-width: 769px) {
            width: 20% !important;
          }
          @media (max-width: 426px) {
            width: 50% !important;
          }
        }
        .Canceled {
          background-color: #6460aa;
          color: $white;
          border-radius: 0;
          width: 12%;
          height: 40px;
          font-weight: 600;
          font-size: 16px;
          @media (max-width: 769px) {
            width: 20% !important;
          }
          @media (max-width: 426px) {
            width: 50% !important;
          }
        }
        .Returned {
          background-color: #827b00;
          color: $white;
          border-radius: 0;
          width: 12%;
          height: 40px;
          font-weight: 600;
          font-size: 16px;
          @media (max-width: 769px) {
            width: 20% !important;
          }
          @media (max-width: 426px) {
            width: 50% !important;
          }
        }
        .black {
          background-color: #32140a;
          color: $white;
          border-radius: 0;
          width: 12%;
          height: 40px;
          font-weight: 600;
          font-size: 16px;
          @media (max-width: 769px) {
            width: 20% !important;
          }
          @media (max-width: 426px) {
            width: 50% !important;
          }
        }
      }
      .history {
        // display: flex;
        // align-items: center;
        // justify-content: space-around;
        .shopping {
          .product {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-right: 6%;
            padding-left: 9%;
            > p {
              font-size: 16px;
              font-weight: 500;
            }
            .showed {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 50%;
              // @media (max-width: 426px) {
              //   width: 62%;
              // }
              @media (max-width: 426px) {
                display: none !important;
              }
              > p {
                font-size: 16px;
                font-weight: 500;
              }
            }
            .showing {
              font-size: 16px;
              font-weight: 500;
              display: none;
              @media (max-width: 426px) {
                display: grid !important;
                width: 60%;
              }
            }
          }
          .blank {
            border: 2px solid #f3f3f3;
          }
          .showItem {
            display: flex;
            align-items: center;
            padding-top: 20px;
            padding-bottom: 20px;
            gap: 16px;
            .foil {
              display: flex;
              align-items: center;
              gap: 26px;
              width: 100%;
              .wear {
                width: 140px;
                height: 170px;
                @media (max-width: 426px) {
                  display: none;
                }
              }
              > div {
                @media (max-width: 426px) {
                  display: none;
                }
                .boota {
                  font-size: 17px;
                  font-weight: 500;
                  width: 170px;
                  text-align: start;
                }
                .size {
                  font-size: 16px;
                  font-weight: 500;
                  width: 170px;
                  text-align: start;
                  text-transform: capitalize;
                }
              }
              .set {
                display: none;
                @media (max-width: 426px) {
                  display: grid !important;
                }
                > div {
                  .wear {
                    width: 140px;
                    height: 170px;
                    @media (max-width: 426px) {
                      display: grid !important;
                      width: 120px;
                    }
                  }
                }
                > div {
                  .boota {
                    font-size: 17px;
                    font-weight: 500;
                    width: 170px;
                    text-align: start;
                  }
                  .size {
                    font-size: 16px;
                    font-weight: 500;
                    width: 170px;
                    text-align: start;
                    text-transform: capitalize;
                  }
                }
              }
              .seting {
                display: flex;
                align-items: center;
                gap: 30px;
                margin-top: -64px;
                @media (max-width: 376px) {
                  display: grid !important;
                  gap: 0;
                  margin-top: -134px;
                }
                @media (max-width: 321px) {
                  display: grid !important;
                  gap: 0;
                  margin-top: -134px;
                  margin-left: -20px;
                }
                .prices {
                  display: flex;
                  align-items: center;
                  margin-left: 229%;
                  gap: 20px;
                  @media (max-width: 426px) {
                    display: grid !important;
                    gap: 0px;
                  }
                  @media (max-width: 2200px) {
                    margin-left: 177% !important;
                  }
                  @media (max-width: 1920px) {
                    margin-left: 135% !important;
                  }
                  @media (max-width: 1600px) {
                    margin-left: 87% !important;
                  }
                  @media (max-width: 1440px) {
                    margin-left: 52% !important;
                  }
                  @media (max-width: 1024px) {
                    margin-left: 36% !important;
                  }
                  @media (max-width: 768px) {
                    margin-left: -16% !important;
                  }
                  @media (max-width: 426px) {
                    margin-left: -25% !important;
                  }
                  .prices1 {
                    font-size: 18px;
                    font-weight: 600;
                    margin: 0;
                  }
                  .prices2 {
                    text-decoration: line-through;
                    color: #998a85;
                  }
                }
                .incree {
                  display: flex;
                  align-items: center;
                  margin-left: 99%;
                  @media (max-width: 2200px) {
                    margin-left: 74% !important;
                  }
                  @media (max-width: 1920px) {
                    margin-left: 57% !important;
                  }
                  @media (max-width: 1600px) {
                    margin-left: 31% !important;
                  }
                  @media (max-width: 1440px) {
                    margin-left: 36% !important;
                  }
                  @media (max-width: 1024px) {
                    margin-left: 16% !important;
                  }
                  @media (max-width: 768px) {
                    margin-left: 2% !important;
                  }
                  @media (max-width: 426px) {
                    margin-left: -3% !important;
                  }
                  .showdata {
                    width: 30px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }
                }
                .total {
                  margin-left: 124%;
                  @media (max-width: 2200px) {
                    margin-left: 100% !important;
                  }
                  @media (max-width: 1920px) {
                    margin-left: 83% !important;
                  }
                  @media (max-width: 1600px) {
                    margin-left: 65% !important;
                  }
                  @media (max-width: 1440px) {
                    margin-left: 57% !important;
                  }
                  @media (max-width: 1024px) {
                    margin-left: 46% !important;
                  }
                  @media (max-width: 768px) {
                    margin-left: 24% !important;
                  }
                  @media (max-width: 426px) {
                    margin-left: 0% !important;
                  }
                  @media (max-width: 376px) {
                    margin-left: -10% !important;
                  }
                  > p {
                    font-size: 18px;
                    font-weight: 600;
                    margin: 0;
                  }
                }
              }
            }
          }
          .blank2 {
            border: 2px solid #f3f3f3;
          }
        }
      }
      .paydetail {
        border: 1px solid #adaeaf;
        border-radius: 10px;
        margin-bottom: 80px;
        margin-top: 30px;
        @media (max-width: 426px) {
          margin-bottom: 30px;
        }
        .paydetailtext {
          font-size: 18px;
          font-weight: 600;
        }
        .total {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 20px 0 20px;
          .pricetotal {
            font-size: 16px;
            font-weight: 400;
            margin: 0!important;
          }
          .prices {
            display: flex;
            align-items: center;
            gap: 20px;
            // margin-top: -30px;
            padding-left: 12px;
            @media (max-width: 426px) {
              padding-left: 28px;
            }
            > p {
              text-align: start;
              font-size: 18px;
              font-weight: 400;
            }
            .secPrice {
              text-decoration: line-through;
              color: #998a85;
              margin: 0;
            }
          }
          .money {
            font-size: 16px;
            font-weight: 500;
            margin: 0;
          }
          .pricetotal0 {
            font-size: 16px;
            font-weight: 400;
            color: #3ca541;
            font-weight: 500;
          }
          .money0 {
            font-size: 16px;
            font-weight: 500;
            color: #3ca541;
            font-weight: 500;
          }
        }
        .total1 {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 20px 0 20px;
          .pricetotal1 {
            font-size: 18px;
            font-weight: 700;
          }
          .money1 {
            font-size: 18px;
            font-weight: 700;
          }
        }
        .blank3 {
          border: 1px solid #adaeaf;
          margin: 0 20px 0 20px;
        }
      }
      .paydetail1 {
        border: 1px solid #adaeaf;
        border-radius: 10px;
        // margin-bottom: 120px;
        margin-top: 30px;
        @media (max-width: 426px) {
          margin-top: 0;
        }
        .paydetailtext1 {
          font-size: 18px;
          font-weight: 600;
        }
        .total1 {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 20px 0 20px;
          .pricetotal {
            font-size: 16px;
            font-weight: 400;
          }
          .money1 {
            font-size: 16px;
            font-weight: 500;
          }
        }
        .total1 {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 20px 0 20px;
          .pricetotal1 {
            font-size: 16px;
            font-weight: 500;
            text-transform: capitalize;
          }
          .money1 {
            font-size: 16px;
            font-weight: 500;
          }
        }
        .blank31 {
          border: 1px solid #adaeaf;
          margin: 0 20px 0 20px;
        }
      }
      .paydetail2 {
        border: 1px solid #adaeaf;
        border-radius: 10px;
        margin-bottom: 30px;
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 426px) {
          margin-bottom: 90px;
        }
        .paydetailtext2 {
          font-size: 18px;
          font-weight: 600;
        }
        .choose {
          // border: 1px solid $black;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 4px 20px 4px 20px;
          border-radius: 10px;
          gap: 10px;
          .wallet {
            width: 20px;
            height: 20px;
          }
          .thousand {
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
      .paydetail3 {
        border: 1px solid #adaeaf;
        border-radius: 10px;
        margin-bottom: 30px;
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        .paydetailtext2 {
          font-size: 18px;
          font-weight: 600;
        }
      }
    }
  }
}
.setrate {
  .settext {
    font-size: 20px;
    font-weight: 600;
    margin-top: 0;
  }
  .blank {
    border: 1px solid #d5d5d5;
    opacity: 0.2;
  }
  .setrateing {
    .rates {
      width: 100%;
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      > li {
        > div {
          > div {
            > span {
              > svg {
                width: 40px !important;
                height: 35px !important;
              }
            }
          }
        }
      }
    }
    .settextrate {
      text-align: center;
      font-size: 20px;
      font-weight: 600;
    }
  }
  .provide {
    font-size: 16px;
    font-weight: 400;
    color: red;
    width: 95%;
  }
  .setinput {
    > p {
      font-size: 16px;
      font-weight: 500;
    }
    > textarea {
      width: 100%;
      height: 130px;
      background-color: #f3f3f3;
      border: 0;
      font-size: 16px;
    }
  }
  .savebtn {
    background-color: #32140a;
    color: $white;
    width: 100%;
    height: 40px;
    margin-top: 30px;
  }
}

.orderset {
  .reason {
    font-size: 20px;
    font-weight: 600;
    margin-top: 0;
  }
  .orderblank {
    border: 1px solid #d5d5d5;
    opacity: 0.2;
  }
  .ordersetting {
    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .product {
        font-size: 16px;
        font-weight: 500;
      }
    }
    .setinput {
      > textarea {
        width: 100%;
        height: 130px;
        background-color: #f3f3f3;
        border: 0;
        font-size: 16px;
        margin-bottom: 30px;
      }
    }
    .savebtnOrder {
      width: 100%;
      background-color: #32140a;
      color: $white;
      height: 40px;
    }
  }
}

.setadd {
  .addrate {
    background-color: #32140a;
    color: $white;
    width: 30%;
    height: 40px;
    margin-bottom: 50px;
    @media (max-width: 426px) {
      width: 100%;
    }
  }
  .addrate {
    background-color: #32140a;
    color: $white;
    width: 30%;
    height: 40px;
    margin-bottom: 50px;
    @media (max-width: 426px) {
      width: 100%;
    }
  }
}
