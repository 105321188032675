@import "../../Assets/Sass/variable.scss";

.mainLine {
  @media (max-width: 426px) {
    // width: 110% !important;
    width: 100% !important;
  }
  .couraselMain {
    margin-bottom: 50px;
    .handlecourasel {
      // display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 56px;
      @media (max-width: 1024px) {
        align-items: center;
        justify-content: space-between;
        gap: 56px;
      }
      @media (max-width: 426px) {
        align-items: center;
        justify-content: space-between;
        gap: 20px;
      }
      > button {
        display: grid;
        width: 40px;
        height: 40px;
        border-radius: 50px;
        ::before {
          color: $white !important;
          font-size: 60px !important;
        }
      }
      .slider {
        > div {
          cursor: pointer;
          // width: 100%;
          > img {
            width: 430px;
            height: 430px;
            @media (min-width: 1901px) and (max-width: 2200px) {
              width: 340px;
              height: 340px;
            }
            @media (min-width: 1601px) and (max-width: 1900px) {
              width: 280px;
              height: 280px;
            }
            @media (min-width: 1441px) and (max-width: 1600px) {
              width: 260px;
              height: 260px;
            }
            @media (max-width: 1440px) {
              width: 250px;
              height: 250px;
            }
          }
        }
        > p {
          font-size: 18px;
          font-weight: 700;
          text-transform: uppercase;
          text-align: center !important;
          // margin-left: -46px !important;
        }
      }
    }
    // }
  }
  .banner {
    // margin-bottom: 30px;
    margin-top: 20px;
    overflow-x: hidden;
    overflow-y: hidden;
    white-space: nowrap;
    .bannercourasel {
      > div {
        > div {
          > div {
            > div {
              > div {
                display: grid !important;
                > img {
                  width: 100%;
                  height: 700px;
                  margin-bottom: 30px;
                  display: flex !important;
                  align-items: center !important;
                  background-size: cover !important;
                  background-repeat: no-repeat !important;
                  cursor: pointer;
                  @media (max-width: 1024px) {
                    height: 500px;
                  }
                  @media (max-width: 768px) {
                    height: 400px;
                  }
                  @media (max-width: 426px) {
                    height: 300px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .video {
      width: 100% !important;
      // border:5px solid #000;
      // border-radius: 10px;
      // box-shadow: 0px 0px 10px 0px grey;
    }
  }
  .wearSet {
    margin-bottom: 50px;
    .smain {
      width: 100%;
      @media (max-width: 1024px) {
        width: 100% !important;
      }
      > div {
        > div {
          > div {
            > div {
              > div {
                .slider {
                  position: relative;
                  .hearticon {
                    cursor: pointer;
                    > span {
                      font-size: 20px;
                      position: absolute;
                      margin-top: 10px;
                      color: $black;
                      border: 1px solid $white;
                      background: $white;
                      padding: 6px;
                      border-radius: 20px;
                      right: -216px;
                      // right: 50px;
                      @media (max-width: 426px) {
                        right: 18px !important;
                      }
                    }
                    .pink {
                      font-size: 20px;
                      position: absolute;
                      margin-top: 10px;
                      color: #ff0000;
                      background: $white;
                      padding: 6px;
                      border-radius: 20px;
                      right: -355px;
                      @media (min-width: 1901px) and (max-width: 2200px) {
                        right: -290px;
                      }
                      @media (min-width: 1601px) and (max-width: 1900px) {
                        right: -230px;
                      }
                      @media (min-width: 1441px) and (max-width: 1600px) {
                        right: -175px;
                      }
                      @media (max-width: 1440px) {
                        right: -216px;
                      }
                      @media (max-width: 1024px) {
                        right: 0px;
                      }
                      @media (max-width: 768px) {
                        right: 50px;
                      }
                      // right: 50px;
                      @media (max-width: 426px) {
                        right: 18px !important;
                      }
                    }
                    .normal {
                      font-size: 20px;
                      position: absolute;
                      margin-top: 10px;
                      color: $black;
                      border: 1px solid $white;
                      background: $white;
                      padding: 6px;
                      border-radius: 20px;
                      right: -355px;
                      @media (min-width: 1901px) and (max-width: 2200px) {
                        right: -290px;
                      }
                      @media (min-width: 1601px) and (max-width: 1900px) {
                        right: -230px;
                      }
                      @media (min-width: 1441px) and (max-width: 1600px) {
                        right: -175px;
                      }
                      @media (max-width: 1440px) {
                        right: -216px;
                      }
                      @media (max-width: 1024px) {
                        right: 0px;
                      }
                      @media (max-width: 768px) {
                        right: 50px;
                      }
                      // right: 50px;
                      @media (max-width: 426px) {
                        right: 18px !important;
                      }
                    }
                  }
                  > div {
                    position: static;
                    cursor: pointer;
                    width: 95% !important;
                    @media (max-width: 426px) {
                      width: 80% !important;
                    }
                    > img {
                      width: 530px;
                      // width: 100%;
                      height: 730px;
                      @media (min-width: 1901px) and (max-width: 2200px) {
                        width: 420px;
                        height: 650px;
                      }
                      @media (min-width: 1601px) and (max-width: 1900px) {
                        width: 350px;
                        height: 600px;
                      }
                      @media (min-width: 1441px) and (max-width: 1600px) {
                        width: 300px;
                        height: 500px;
                      }
                      @media (max-width: 1440px) {
                        width: 300px;
                        height: 450px;
                      }
                      @media (max-width: 1024px) {
                        width: 300px;
                      }

                      @media (min-width: 322px) and (max-width: 426px) {
                        width: 230px;
                        height: 400px;
                      }
                      @media (max-width: 321px) {
                        width: 210px;
                        height: 400px;
                      }
                    }
                  }
                  > p {
                    text-align: start;
                    font-size: 18px;
                    font-weight: 400;
                    padding-left: 12px;
                    width: 310px;
                    @media (max-width: 426px) {
                      padding-left: 28px;
                    }
                  }
                  .prices {
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    margin-top: -30px;
                    padding-left: 12px;
                    @media (max-width: 426px) {
                      padding-left: 28px;
                    }
                    > p {
                      text-align: start;
                      font-size: 18px;
                      font-weight: 400;
                    }
                    .secPrice {
                      text-decoration: line-through;
                      color: #998a85;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .wear1 {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 56px;
    margin-bottom: 20px;
    @media (max-width: 1024px) {
      display: grid !important;
      grid-template-columns: repeat(3, 1fr) !important;
    }
    @media (max-width: 768px) {
      display: grid !important;
      grid-template-columns: repeat(2, 1fr) !important;
    }
    .slider {
      position: relative;
      > div {
        cursor: pointer;
        // width: 100%;
        > img {
          width: 250px;
          height: 250px;
          @media (max-width: 426px) {
            width: 190px;
            height: 230px;
          }
        }
      }
      > p {
        font-size: 18px;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center !important;
        // margin-left: -46px !important;
      }
    }
  }
  .wear {
    margin-bottom: 50px;
    @media (max-width: 426px) {
      margin-bottom: 0px;
    }
    > div {
      @media (max-width: 1024px) {
        display: grid !important;
        grid-template-columns: repeat(3, 1fr) !important;
      }
      @media (max-width: 768px) {
        display: grid !important;
        grid-template-columns: repeat(2, 1fr) !important;
      }
      @media (max-width: 426px) {
        display: grid !important;
        grid-template-columns: repeat(2, 1fr) !important;
      }
      @media (max-width: 376px) {
        display: grid !important;
        grid-template-columns: repeat(2, 1fr) !important;
        // margin-left: -22px !important;
      }
      @media (max-width: 321px) {
        display: grid !important;
        grid-template-columns: repeat(2, 1fr) !important;
        // margin-left: -31px !important;
        gap: 4px;
      }
      .setMain {
        width: 100%;
        @media (max-width: 1024px) {
          width: 100% !important;
        }
        .slider {
          position: relative;
          .hearticon {
            cursor: pointer;
            > span {
              font-size: 20px;
              position: absolute;
              margin-top: 10px;
              color: $black;
              border: 1px solid $white;
              background: $white;
              padding: 6px;
              border-radius: 20px;
              right: 33px;
              @media (max-width: 426px) {
                right: 18px !important;
              }
            }
            .pink {
              font-size: 20px;
              position: absolute;
              margin-top: 10px;
              color: #ff0000;
              background: $white;
              padding: 6px;
              border-radius: 20px;
              right: 33px;
              @media (max-width: 426px) {
                right: 18px !important;
              }
            }
            .normal {
              font-size: 20px;
              position: absolute;
              margin-top: 10px;
              color: $black;
              border: 1px solid $white;
              background: $white;
              padding: 6px;
              border-radius: 20px;
              right: 33px;
              @media (max-width: 426px) {
                right: 18px !important;
              }
            }
          }
          > div {
            position: static;
            cursor: pointer;
            width: 95%;
            @media (max-width: 426px) {
              width: 80%;
            }
            > img {
              width: 100%;
              height: 480px;
              @media (max-width: 426px) {
                width: 192px;
                height: 330px;
              }
              @media (max-width: 321px) {
                width: 190px !important;
              }
            }
          }
          > p {
            text-align: start;
            font-size: 18px;
            font-weight: 400;
            padding-left: 12px;
            @media (max-width: 426px) {
              padding-left: 28px;
            }
          }
          .prices {
            display: flex;
            align-items: center;
            gap: 20px;
            margin-top: -30px;
            padding-left: 12px;
            @media (max-width: 426px) {
              padding-left: 28px;
            }
            > p {
              text-align: start;
              font-size: 18px;
              font-weight: 400;
            }
            .secPrice {
              text-decoration: line-through;
              color: #998a85;
            }
          }
        }
      }
    }
  }

  .reseller {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 20px 0;
    border: 1px solid #e5e5e5;
    @media (max-width: 1024px) {
      display: grid;
      align-items: center;
      justify-content: center;
    }
    > div {
      > img {
        // width: 800px !important;
        // height: 300px !important;
        // margin: 30px 0;
        display: flex;
        align-items: center;
        background-size: cover;
        background-repeat: no-repeat;
        @media (max-width: 1024px) {
          width: 640px !important;
        }
        @media (max-width: 426px) {
          width: 400px !important;
          height: 250px;
        }
        @media (max-width: 376px) {
          width: 350px !important;
        }
        @media (max-width: 321px) {
          width: 310px !important;
        }
      }
    }
    .details {
      margin-right: 70px;
      @media (max-width: 1024px) {
        margin-left: 0;
        margin-bottom: 40px;
      }
      @media (max-width: 426px) {
        margin-left: 0;
        margin-bottom: 40px;
        margin-right: 0px;
      }
      > div {
        > img {
          @media (max-width: 321px) {
            margin-left: 0px !important;
          }
        }
      }
      > p {
        font-size: 20px;
        font-weight: 500;
        @media (max-width: 426px) {
          // margin-left: 45px !important;
          font-size: 16px;
        }
      }
      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 30px;
        @media (max-width: 321px) {
          // margin-left: 0px !important;
          // display: grid;
          // margin-left: 45px !important;
          gap: 10px;
        }
        .google {
          width: 150px;
          height: 100ox;
          cursor: pointer;
          @media (max-width: 426px) {
            width: 100px;
            height: 100ox;
            cursor: pointer;
          }
        }
      }
    }
  }
}
