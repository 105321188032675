@import "../../../Assets/Sass/variable.scss";

.maining {
  @media (max-width: 321px) {
    width: 100% !important;
  }
  .main {
    background-color: #ebe8e7;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // margin-bottom: 50px;
    .main2 {
      // justify-content: space-around;
      padding: 15px 0 45px 0;

      .main3 {
        .customer {
          .testimonial {
            font-size: 26px;
            font-weight: 700;
            text-align: start;
          }
          .point {
            font-size: 16px;
            font-weight: 500;
            text-align: start;
          }
        }
      }
      .slidermain {
        background-color: $white;
        border-radius: 15px;
        padding: 0px 40px 0px 40px;
        margin-top: 30px;
        @media (max-width: 426px) {
          padding: 0 !important;
        }
        .slike {
          padding: 30px;
          > button {
            display: none !important;
          }
          .sets {
            display: flex;
            align-items: center;
            justify-content: center;
            // gap: 10px;
            width: 98% !important;
            .first {
              background-color: #f4f3ef;
              padding: 30px 30px 30px 30px;
              border-radius: 15px;
              width: 100% !important;
              .second {
                display: flex;
                align-items: center;
                justify-content: space-around;
                @media (max-width: 768px) {
                  display: grid !important;
                }
                gap: 30px;
                .reviewImg {
                  width: 250px;
                  height: 250px;
                  border-radius: 15px;
                }
                .set {
                  display: grid;
                  width: 100% !important;

                  .reviewImg2 {
                    width: 50px;
                    height: 50px;
                  }
                  > textarea {
                    width: 100% !important;
                    height: 130px !important;
                    background-color: #f4f3ef !important;
                    border: 0 !important;
                    color: $black !important;
                  }
                  .writeReview {
                    font-size: 16px;
                    font-weight: 500;
                    text-align: start;
                    width: 100%;
                    height: 100px;
                  }
                  .setName {
                    display: grid;
                    align-items: center;
                    justify-content: flex-end;
                    .dataName {
                      font-size: 16px;
                      font-weight: 500;
                    }
                    .ratingset {
                      @media (max-width: 1024px) {
                        width: 118%;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
