@import "../../Assets/Sass/variable.scss";

.offer {
  background-color: #02635a;
  color: $white;
  padding: 15px;
  text-align: center;
  font-weight: 700;
  // width: 100%;
  @media (max-width: 426px) {
    // width: 109% !important;
    width: 100% !important;
  }
  @media (max-width: 376px) {
    // width: 110% !important;
    width: 100% !important;
  }
  @media (max-width: 321px) {
    // width: 119% !important;
    width: 100% !important;
  }
}
.headermain {
  background-color: $white;
  justify-content: center;
  padding: 15px;
  margin-bottom: 50px;
  @media (max-width: 426px) {
    padding: 15px 0 0 40px;
  }
  @media (max-width: 321px) {
    padding: 15px 0 0 0px;
  }
  .headerlogo {
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 321px) {
      margin-left: 0px !important;
    }
    > div {
      cursor: pointer;
      > img {
        width: auto;
        height: 80px;
        @media (max-width: 426px) {
          width: 230px;
          height: 80px;
        }
        @media (max-width: 376px) {
          width: 200px !important;
          height: 70px !important;
        }
      }
    }
  }
  .searchinput {
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1024px) {
      margin-left: 40px !important;
    }
    @media (max-width: 768px) {
      display: none;
    }
    > span {
      border: 1px solid $darkgrey;
      padding: 10px 20px;
      border-radius: 20px;
      > span {
        margin-inline-end: 12px;
      }
    }
  }
  .searchinput2 {
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 2560px) {
      display: none !important;
    }
    @media (max-width: 1440px) {
      display: none !important;
    }
    @media (max-width: 1024px) {
      display: none !important;
    }
    @media (max-width: 768px) {
      width: 100%;
      margin-top: 20px;
      display: flex !important;
    }
    @media (max-width: 426px) {
      width: 100%;
      margin-top: 20px;
      display: flex !important;
      margin-bottom: 10px;
    }
    @media (max-width: 426px) {
      width: 100%;
      margin-top: 20px;
      display: flex !important;
      margin-bottom: 10px;
      margin-left: -15px;
    }
    > span {
      border: 1px solid $darkgrey;
      padding: 10px 20px;
      border-radius: 20px;
      > span {
        margin-inline-end: 12px;
      }
    }
  }
  .headericon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 768px) {
      display: none !important;
    }
    > div {
      cursor: pointer;
      > img {
        width: 25px;
        height: 25px;
      }
    }
    .hrDropdown {
      > div {
        cursor: pointer;
        > img {
          width: 25px;
          height: 25px;
        }
      }
    }
    .badge {
      > div {
        cursor: pointer;
        > img {
          width: 25px;
          height: 25px;
        }
      }
    }
  }
  .headericon2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // display: none !important;
    @media (max-width: 2560px) {
      display: none;
      // display: grid;
    }
    @media (max-width: 1440px) {
      display: none;
      // display: grid;
    }
    @media (max-width: 1024px) {
      display: none;
      // display: grid;
    }
    @media (max-width: 768px) {
      display: flex;
    }
    > div {
      cursor: pointer;
      > img {
        width: 25px;
        height: 25px;
      }
    }
    .hrDropdown {
      > div {
        cursor: pointer;
        > img {
          width: 25px;
          height: 25px;
        }
      }
    }
    .badge {
      @media (max-width: 321px) {
        margin-left: -10px !important;
      }
      > div {
        cursor: pointer;
        > img {
          width: 25px;
          height: 25px;
          @media (max-width: 321px) {
            margin-left: 20px !important;
          }
        }
      }
    }
  }
}
.tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: -50px;
  margin-bottom: 40px;
  @media (max-width: 1024px) {
    display: grid !important;
    grid-template-columns: repeat(6, 1fr) !important;
  }
  @media (max-width: 768px) {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr) !important;
    text-align: start;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 426px) {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr) !important;
    // margin-left: 95px !important;
    text-align: start;
  }
  > div {
    cursor: pointer;
    > p {
      color: #281008;
      font-size: 18px;
      font-weight: 600;
      text-transform: uppercase;
      @media (max-width: 1024px) {
        font-size: 16px;
      }
      @media (max-width: 768px) {
        width: 150px !important;
      }
    }
  }
}
