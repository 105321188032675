@import "../../Assets/Sass/variable.scss";

// .welMain {
//   @media (max-width: 321px) {
//     width: 109% !important;
//   }
.addcart {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  text-align: start;
  gap: 30px;
  @media (max-width: 1440px) {
    justify-content: space-between;
    gap: 25px;
  }
  @media (max-width: 1024px) {
    gap: 25px;
  }
  @media (max-width: 768px) {
    display: grid !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .cartImg {
    height: 770px;
    width: auto;
    // width: 100%;
    // height: 800px;
    position: relative;
    @media (max-width: 1024px) {
      width: 450px;
    }
    @media (max-width: 768px) {
      width: 700px;
    }
    @media (max-width: 426px) {
      width: 100%;
      // margin-left: 43px;
    }
    @media (max-width: 321px) {
      width: 100%;
      // margin-left: 16px;
    }
  }

  .sider {
    // margin-top: 25px;
    @media (max-width: 1024px) {
      width: 100%;
    }
    @media (max-width: 426px) {
      width: 100%;
      // margin-left: 44px;
    }
    @media (max-width: 321px) {
      // width: 80%;
      margin-left: 16px;
    }
    .Foil {
      font-size: 20px;
      font-weight: 700;
      margin: 0;
    }
    .prices {
      display: flex;
      align-items: center;
      margin-top: 10px;
      gap: 20px;
      .price1 {
        font-size: 20px;
        font-weight: 700;
        margin: 0;
      }
      .price2 {
        text-decoration: line-through;
        color: #998a85;
      }
      .price3 {
        font-size: 17px;
        color: #3ca541;
        font-weight: 700;
      }
      .price30 {
        color: red;
        font-size: 17px;
        font-weight: 700;
      }
    }
    .rating {
      display: flex;
      align-items: center;
      gap: 20px;
      > p {
        font-size: 18px;
        font-weight: 400;
      }
    }
    .setcolor {
      display: flex;
      align-items: center;
      gap: 20px;
      > p {
        font-size: 18px;
        font-weight: 600;
        margin-top: -30px;
       
      }
      > div {
        display: flex;
        align-items: center;
        gap: 20px;
        display: grid !important;
        grid-template-columns: repeat(8, 1fr) !important;
        @media (max-width: 1024px) {
          display: grid !important;
          grid-template-columns: repeat(5, 1fr) !important;
        }
        @media (max-width: 768px) {
          display: grid !important;
          grid-template-columns: repeat(7, 1fr) !important;
        }
        @media (max-width: 426px) {
          display: grid !important;
          grid-template-columns: repeat(4, 1fr) !important;
        }
        @media (max-width: 376px) {
          display: grid !important;
          grid-template-columns: repeat(3, 1fr) !important;
          gap: 0 !important; 
        }
        > div {
          .colorImg {
            border: 2px solid;
            border-radius: 11px;
            > div {
              display: flex;
              align-items: center;
              justify-content: center;
              > img {
                height: 100px;
                width: 60px;
                cursor: pointer;
                border-radius: 10px;
              }
            }
          }
          .colorImg2 {
            > div {
              display: flex;
              align-items: center;
              justify-content: center;
              > img {
                height: 100px;
                width: 60px;
                cursor: pointer;
                border-radius: 10px;
              }
            }
          }
          > p {
            font-size: 18px;
            font-weight: 600;
            text-align: center;
            @media (max-width: 376px) {
              font-size: 12px !important;
             }
          }
        }
      }
    }
    .setbtn {
      display: flex;
      align-items: center;
      gap: 20px;
      border-bottom: 1px solid #cccccc;
      // margin-top: 10px;
      > p {
        font-size: 18px;
        font-weight: 600;
      }
      > div {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-left: 11px;
        @media (max-width: 1024px) {
          display: grid !important;
          grid-template-columns: repeat(8, 1fr) !important;
        }
        @media (max-width: 426px) {
          display: grid !important;
          grid-template-columns: repeat(4, 1fr) !important;
        }
        @media (max-width: 321px) {
          display: grid !important;
          grid-template-columns: repeat(4, 1fr) !important;
        }
        .xs {
          border-radius: 0;
          width: 100%;
          height: 45px;
          background-color: #32140a;
          color: $white;
          border-width: 0;
          > span {
            font-weight: 600;
            text-transform: uppercase;
          }
        }
        .xs2 {
          border-radius: 0;
          width: 100%;
          height: 45px;
          background-color: #ebe8e7;
          border-width: 0;
          > span {
            font-weight: 600;
            text-transform: uppercase;
          }
        }
        .xs3 {
          border-radius: 0;
          width: 200px;
          height: 45px;
          background-color: #32140a;
          color: $white;
          border-width: 0;
          > span {
            font-weight: 600;
            text-transform: uppercase;
          }
        }
        .xs4 {
          border-radius: 0;
          width: 200px;
          height: 45px;
          background-color: #ebe8e7;
          border-width: 0;
          > span {
            font-weight: 600;
            text-transform: uppercase;
          }
        }
        .s {
          border-radius: 0;
          width: 50px;
          height: 45px;
          background-color: #ebe8e7;
          border-width: 0;
          > span {
            font-weight: 600;
          }
        }
      }
    }
    .incree {
      display: flex;
      align-items: center;
      .decree {
        border-radius: 0;
        border-bottom-left-radius: 20px;
        border-top-left-radius: 20px;
        background-color: #ebe8e7;
      }
      .showdata {
        border: 1px solid #e3e3e3;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .incre2 {
        border-radius: 0;
        border-bottom-right-radius: 20px;
        border-top-right-radius: 20px;
        background-color: #ebe8e7;
      }
    }
    .add {
      display: flex;
      align-items: center;
      gap: 10px;
      @media (max-width: 1024px) {
        display: grid;
      }
      .cart {
        background-color: #32140a;
        color: $white;
        width: 370px;
        height: auto;
        padding: 10px;
        border-radius: 0;
        @media (max-width: 1024px) {
          width: 100%;
        }
        @media (max-width: 426px) {
          width: 90%;
        }
      }
      .favourite {
        background-color: $white;
        color: #32140a;
        width: 370px;
        height: auto;
        padding: 10px;
        border-radius: 0;
        @media (max-width: 1024px) {
          width: 100%;
        }
        @media (max-width: 426px) {
          width: 90%;
        }
      }
    }
    .add1 {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-top: 20px;
      margin-bottom: 20px;
      .userbtn {
        background-color: #32140a;
        color: $white;
        width: 90%;
        height: auto;
        padding: 10px;
        border-radius: 0;
        @media (max-width: 1024px) {
        }
        @media (max-width: 426px) {
          width: 90%;
        }
      }
    }
    .names {
      text-align: start;
      margin-top: 10px;
      margin-bottom: 10px;
      > div {
        display: flex;
        align-items: center;
        .brand {
          font-weight: 500;
          font-size: 16px;
          margin: 5px;
        }
      }
      .ganga {
        margin-left: 5px;
      }
    }
    .share {
      display: flex;
      border-top: 1px solid #cccccc;
      align-items: center;
      gap: 20px;
      > p {
        font-size: 18px;
        font-weight: 600;
      }
      .setround {
        display: flex;
        align-items: center;
        gap: 10px;
        > div {
          // display: flex;
          // align-items: center;
          // justify-content: center;
          > div {
            > img {
              width: 40px;
              height: 40px;
              cursor: pointer;
            }
          }
        }
      }
    }
    .lastimg {
      width: 100%;
      > div {
        > img {
          width: 620px;
          height: 110px;
          @media (max-width: 1024px) {
            width: 450px;
            height: 100px;
          }
          @media (max-width: 768px) {
            width: 100%;
            height: 100px;
          }
          @media (max-width: 426px) {
            width: 90%;
            height: 100px;
          }
        }
      }
    }
  }
}
.couraselMain {
  position: absolute;
  margin-top: -68px;
  @media (max-width: 768px) {
    display: none !important;
  }
  .fixcourasel {
    margin-bottom: 30px;
    margin-top: 90px;
    .handlecourasel {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 18px;
      display: grid !important;
      grid-template-columns: repeat(5, 1fr) !important;
      @media (max-width: 426px) {
        display: grid !important;
        grid-template-columns: repeat(4, 1fr) !important;
      }
      @media (max-width: 376px) {
        display: grid !important;
        grid-template-columns: repeat(3, 1fr) !important;
      }
      .slider {
        > div {
          display: flex;
          align-items: center;
          justify-content: center;
          > img {
            height: 110px;
            width: 80px;
            cursor: pointer;
            border-radius: 10px;
          }
        }
      }
    }
  }
  .fixcourasel2 {
    // margin-bottom: 60px;
    margin-top: 80px;
    .handlecourasel {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 18px;
      display: grid !important;
      grid-template-columns: repeat(5, 1fr) !important;
      @media (max-width: 426px) {
        display: grid !important;
        grid-template-columns: repeat(4, 1fr) !important;
      }
      @media (max-width: 376px) {
        display: grid !important;
        grid-template-columns: repeat(3, 1fr) !important;
      }
      .slider {
        > div {
          display: flex;
          align-items: center;
          justify-content: center;
          > img {
            height: 110px;
            width: 80px;
            cursor: pointer;
            border-radius: 10px;
          }
        }
      }
    }
  }
}

.couraselMain3 {
  position: absolute;
  margin-top: -68px;
  display: none !important;
  @media (max-width: 768px) {
    display: grid !important;
  }
  .fixcourasel {
    // margin-bottom: 30px;
    margin-top: 50px;
    .handlecourasel {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 18px;
      display: grid !important;
      grid-template-columns: repeat(5, 1fr) !important;
      @media (max-width: 768px) {
        display: grid !important;
        grid-template-columns: repeat(7, 1fr) !important;
      }
      @media (max-width: 426px) {
        display: grid !important;
        grid-template-columns: repeat(4, 1fr) !important;
        // margin-left: 44px;
      }
      @media (max-width: 376px) {
        display: grid !important;
        grid-template-columns: repeat(3, 1fr) !important;
        margin-left: 18px;
      }
      .slider {
        > div {
          display: flex;
          align-items: center;
          justify-content: center;
          > img {
            height: 110px;
            width: 80px;
            cursor: pointer;
            border-radius: 10px;
          }
        }
      }
    }
  }
  .fixcourasel2 {
    // margin-bottom: 60px;
    margin-top: 80px;
    .handlecourasel {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 18px;
      .slider {
        > div {
          display: flex;
          align-items: center;
          justify-content: center;
          > img {
            height: 110px;
            width: 80px;
            cursor: pointer;
            border-radius: 10px;
          }
        }
      }
    }
  }
}
.settabs {
  margin-top: 20px;
  margin-bottom: 30px;
  .tabs {
    width: 100%;
    > div {
      > div {
        > div {
          > div {
            > div {
              color: $black !important;
              font-size: 16px;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}
.settabs2 {
  margin-bottom: 30px;
  .tabs {
    width: 100%;
    > div {
      > div {
        > div {
          > div {
            > div {
              color: $black !important;
              font-size: 16px;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}
.wearSet {
  margin-bottom: 50px;
  .smain {
    width: 100%;
    @media (max-width: 1024px) {
      width: 100% !important;
    }
    > div {
      > div {
        > div {
          > div {
            > div {
              .slider {
                position: relative;
                .hearticon {
                  cursor: pointer;
                  > span {
                    font-size: 20px;
                    position: absolute;
                    margin-top: 10px;
                    color: $black;
                    border: 1px solid $white;
                    background: $white;
                    padding: 6px;
                    border-radius: 20px;
                    right: -216px;
                    // right: 50px;
                    @media (max-width: 426px) {
                      right: 18px !important;
                    }
                  }
                  .pink {
                    font-size: 20px;
                    position: absolute;
                    margin-top: 10px;
                    color: #ff0000;
                    background: $white;
                    padding: 6px;
                    border-radius: 20px;
                    right: -355px;
                    @media (min-width: 1901px) and (max-width: 2200px) {
                      right: -290px;
                    }
                    @media (min-width: 1601px) and (max-width: 1900px) {
                      right: -230px;
                    }
                    @media (min-width: 1441px) and (max-width: 1600px) {
                      right: -175px;
                    }
                    @media (max-width: 1440px) {
                      right: -216px;
                    }
                    @media (max-width: 1024px) {
                      right: 0px;
                    }
                    @media (max-width: 768px) {
                      right: 50px;
                    }
                    // right: 50px;
                    @media (max-width: 426px) {
                      right: 18px !important;
                    }
                  }
                  .normal {
                    font-size: 20px;
                    position: absolute;
                    margin-top: 10px;
                    color: $black;
                    border: 1px solid $white;
                    background: $white;
                    padding: 6px;
                    border-radius: 20px;
                    right: -355px;
                    @media (min-width: 1901px) and (max-width: 2200px) {
                      right: -290px;
                    }
                    @media (min-width: 1601px) and (max-width: 1900px) {
                      right: -230px;
                    }
                    @media (min-width: 1441px) and (max-width: 1600px) {
                      right: -175px;
                    }
                    @media (max-width: 1440px) {
                      right: -216px;
                    }
                    @media (max-width: 1024px) {
                      right: 0px;
                    }
                    @media (max-width: 768px) {
                      right: 50px;
                    }
                    // right: 50px;
                    @media (max-width: 426px) {
                      right: 18px !important;
                    }
                  }
                }
                > div {
                  position: static;
                  cursor: pointer;
                  width: 95% !important;
                  @media (max-width: 426px) {
                    width: 80% !important;
                  }
                  > img {
                    width: 300px;
                    height: auto;
                    // @media (min-width: 1901px) and (max-width: 2200px) {
                    //   width: 100%;
                    //   height: 390px;
                    // }
                    // @media (min-width: 1601px) and (max-width: 1900px) {
                    //   width: 350px;
                    //   height: 600px;
                    // }
                    // @media (min-width: 1441px) and (max-width: 1600px) {
                    //   width: 300px;
                    //   height: 500px;
                    // }
                    // @media (max-width: 1440px) {
                    //   width: 300px;
                    //   height: 450px;
                    // }
                    // @media (max-width: 1288px) {
                    //   width: 100%;
                    //   height: 390px;
                    // }

                    @media (min-width: 322px) and (max-width: 426px) {
                      width: 230px;
                      height: 400px;
                    }
                    @media (max-width: 321px) {
                      width: 210px;
                      height: 400px;
                    }
                  }
                }
                > p {
                  text-align: start;
                  font-size: 18px;
                  font-weight: 400;
                  padding-left: 12px;
                  width: 310px;
                  @media (max-width: 426px) {
                    padding-left: 28px;
                  }
                }
                .prices {
                  display: flex;
                  align-items: center;
                  gap: 20px;
                  margin-top: -30px;
                  padding-left: 12px;
                  @media (max-width: 426px) {
                    padding-left: 28px;
                  }
                  > p {
                    text-align: start;
                    font-size: 18px;
                    font-weight: 400;
                  }
                  .secPrice {
                    text-decoration: line-through;
                    color: #998a85;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.wear {
  margin-bottom: 50px;
  @media (max-width: 426px) {
    margin-bottom: 0px;
  }
  > div {
    @media (max-width: 1024px) {
      display: grid !important;
      grid-template-columns: repeat(3, 1fr) !important;
    }
    @media (max-width: 768px) {
      display: grid !important;
      grid-template-columns: repeat(2, 1fr) !important;
    }
    @media (max-width: 426px) {
      display: grid !important;
      grid-template-columns: repeat(2, 1fr) !important;
    }
    @media (max-width: 376px) {
      display: grid !important;
      grid-template-columns: repeat(2, 1fr) !important;
      margin-left: -22px !important;
    }
    @media (max-width: 321px) {
      display: grid !important;
      grid-template-columns: repeat(2, 1fr) !important;
      margin-left: -31px !important;
      gap: 4px;
    }
    .setMain {
      width: 100%;
      @media (max-width: 1024px) {
        width: 100% !important;
      }
      .slider {
        position: relative;
        .hearticon {
          cursor: pointer;
          > span {
            font-size: 20px;
            position: absolute;
            margin-top: 10px;
            color: $black;
            border: 1px solid $white;
            background: $white;
            padding: 6px;
            border-radius: 20px;
            right: 33px;
            @media (max-width: 426px) {
              right: 18px !important;
            }
          }
          .pink {
            font-size: 20px;
            position: absolute;
            margin-top: 10px;
            color: #ff0000;
            background: $white;
            padding: 6px;
            border-radius: 20px;
            right: 33px;
            @media (max-width: 426px) {
              right: 18px !important;
            }
          }
          .normal {
            font-size: 20px;
            position: absolute;
            margin-top: 10px;
            color: $black;
            border: 1px solid $white;
            background: $white;
            padding: 6px;
            border-radius: 20px;
            right: 33px;
            @media (max-width: 426px) {
              right: 18px !important;
            }
          }
        }
        > div {
          position: static;
          cursor: pointer;
          width: 95%;
          @media (max-width: 426px) {
            width: 80%;
          }
          > img {
            width: auto;
            height: 500px;
            @media (max-width: 426px) {
              width: 192px;
              height: 330px;
            }
            @media (max-width: 321px) {
              width: 190px !important;
            }
          }
        }
        > p {
          text-align: start;
          font-size: 18px;
          font-weight: 400;
          padding-left: 12px;
          @media (max-width: 426px) {
            padding-left: 28px;
          }
        }
        .prices {
          display: flex;
          align-items: center;
          gap: 20px;
          margin-top: -30px;
          padding-left: 12px;
          @media (max-width: 426px) {
            padding-left: 28px;
          }
          > p {
            text-align: start;
            font-size: 18px;
            font-weight: 400;
          }
          .secPrice {
            text-decoration: line-through;
            color: #998a85;
          }
        }
      }
    }
  }
}
// }
