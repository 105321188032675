.setMain {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  @media (max-width: 768px) {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr) !important;
  }
  @media (max-width: 426px) {
    display: grid !important;
    grid-template-columns: repeat(1, 1fr) !important;
  }
  .setbox {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 100%;
    height: 350px;
    border-radius: 20px;
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30vh;
      .setimg {
        width: 100px;
        height: 100px;
      }
    }
    .address {
      font-size: 18px;
      font-weight: 700;
      text-align: center;
    }
    .plaza {
      font-size: 16px;
      font-weight: 500;
      text-align: center;
    }
  }
}
