@import "../../Assets/Sass/variable.scss";

.setbtn {
  @media (max-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 23px;
  }
  @media (max-width: 426px) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    // padding-left: 1%;
    padding-right: 0px;
    width: 100%;
  }
  @media (max-width: 321px) {
    width: 100% !important;
    padding-right: 0px;
  }
  .filterbtn {
    display: none;
    @media (max-width: 1024px) {
      display: inline;
      background-color: #32140a;
      color: $white;
      width: 30%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    @media (max-width: 426px) {
      width: 96%;
    }
  }
}
.mainLine {
  @media (max-width: 426px) {
    width: 100%;
  }
  @media (max-width: 321px) {
    width: 100%;
  }

  .sider {
    margin-left: 30px;
    position: relative;
    @media (max-width: 1024px) {
      display: none;
    }
    > div {
      // height: 1200px;
      // overflow-y: scroll;
      .main {
        margin: 16px;
        .clear {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 20px 0 20px;
          .filter {
            font-size: 14px;
            font-weight: 600;
            cursor: pointer;
          }
          .clear2 {
            font-size: 14px;
            font-weight: 600;
            cursor: pointer;
          }
        }
        .blank {
          border: 1px solid #998a85;
        }
        .brand {
          font-size: 20px;
          font-weight: 600;
          text-align: start;
          padding: 0 20px 0 20px;
        }
        .check {
          // display: flex;
          align-items: center;
          justify-content: space-between;
          // padding: 0 20px 0 20px;

          .checkname {
            display: grid;
            align-items: center;
            justify-content: space-between;
            // grid-template-columns: repeat(2, 1fr);
            text-align: start;
            height: 300px;
            overflow-y: auto;
            .list {
              font-size: 12px;
              font-weight: 400;
              margin-bottom: 10px;
            }
          }
          .checkname3 {
            display: grid;
            align-items: center;
            justify-content: space-between;
            grid-template-columns: repeat(2, 1fr);
            text-align: start;
            height: 100px;
            overflow-y: auto;
            .list {
              font-size: 12px;
              font-weight: 400;
              margin-bottom: 10px;
            }
          }
          .checkname6 {
            display: grid;
            align-items: center;
            justify-content: space-between;
            grid-template-columns: repeat(2, 1fr);
            text-align: start;
            height: 250px;
            overflow-y: auto;
            .list {
              font-size: 12px;
              font-weight: 400;
              margin-bottom: 10px;
            }
          }
          .checkname2 {
            display: grid;
            align-items: center;
            justify-content: space-between;
            .list2 {
              font-size: 12px;
              font-weight: 400;
              margin-bottom: 10px;
            }
          }
        }
        .colors {
          .color {
            font-size: 20px;
            font-weight: 600;
            text-align: start;
            border-top: 1px solid #998a85;
            // padding: 10px 20px 0 20px;
          }
        }
      }
    }
  }

  .wear {
    margin-left: -120px;
    margin-bottom: 80px;
    @media (max-width: 1024px) {
      margin-left: 0;
    }
    .new {
      .home {
        font-size: 16px;
        font-weight: 600;
        text-align: start;
        text-transform: capitalize;
      }
    }
    .shop {
      background-color: #f3f3f3;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 40px 10px 40px;
      margin-bottom: 20px;
      .show {
        font-size: 16px;
        font-weight: 600;
      }
      .select {
        width: 20% !important;
        border: 1px solid #d9d9d9;
        border-radius: 7px;
        height: 40px;
        text-transform: capitalize;
        @media (max-width: 768px) {
          width: 30% !important;
        }
        @media (max-width: 426px) {
          width: 100% !important;
        }
      }
    }
    .setMain {
      width: 100%;
      .slider {
        position: relative;
        .hearticon {
          cursor: pointer;
          > span {
            font-size: 20px;
            position: absolute;
            margin-top: 10px;
            color: $black;
            border: 1px solid $white;
            background: $white;
            padding: 6px;
            border-radius: 20px;
            right: 50px;
          }
          .pink {
            font-size: 20px;
            position: absolute;
            margin-top: 10px;
            color: #ff0000;
            // border: 1px solid $white;
            background: $white;
            padding: 6px;
            border-radius: 20px;
            right: 50px;
            @media (max-width: 426px) {
              right: 20px;
            }
          }
          .normal {
            font-size: 20px;
            position: absolute;
            margin-top: 10px;
            color: $black;
            border: 1px solid $white;
            background: $white;
            padding: 6px;
            border-radius: 20px;
            right: 50px;
            @media (max-width: 426px) {
              right: 20px;
            }
          }
        }
        > div {
          position: static;
          cursor: pointer;
          width: 95%;
          > img {
            // object-fit: cover;
            // width: 280px;
            // height: 490px;
            width: 100%;
            // height: 730px;
            @media (max-width: 2200px) {
              width:auto;
              height: 500px;
            }
            @media (max-width: 1900px) {
              width:auto;
              height: 500px;
            }
            @media (max-width: 1600px) {
              width:100%;
              height: 500px;
            }
            @media (max-width: 1440px) {
              width: 100%;
              height: 530px;
            }
            @media (max-width: 1024px) {
              width: 100%;
              height: 480px;
            }
            @media (max-width: 768px) {
              width: 100%;
              height: 375px;
            }
            @media (max-width: 426px) {
              width: 100%;
              height: 255px;
            }
            @media (max-width: 376px) {
              width: 100%;
              height: 255px;
            }
            @media (max-width: 321px) {
              width: 100%;
              height: 255px;
            }
          }
        }
        > p {
          text-align: start;
          font-size: 18px;
          font-weight: 400;
          padding-left: 12px;
        }
        .prices {
          display: flex;
          align-items: center;
          gap: 20px;
          margin-top: -30px;
          padding-left: 12px;
          > p {
            text-align: start;
            font-size: 18px;
            font-weight: 400;
          }
          .secPrice {
            text-decoration: line-through;
            color: #998a85;
          }
        }
      }
    }
    // .pagination {
    // width: 100%;
    // > li {
    //   > span {
    //     border-radius: 20px !important;
    //   }
    // }
    // }
  }
}

.sider0 {
  margin-left: 30px;
  position: relative;
  @media (max-width: 1024px) {
    display: inline;
  }
  .main {
    margin: 16px;
    .clear {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px 0 20px;
      .filter {
        font-size: 14px;
        font-weight: 600;
        cursor: pointer;
      }
      .clear2 {
        font-size: 14px;
        font-weight: 600;
        cursor: pointer;
      }
    }
    .blank {
      border: 1px solid #998a85;
    }
    .brand {
      font-size: 20px;
      font-weight: 600;
      text-align: start;
      padding: 0 20px 0 20px;
    }
    .check {
      // display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px 0 20px;

      .checkname {
        display: grid;
        align-items: center;
        justify-content: space-between;
        grid-template-columns: repeat(2, 1fr);
        text-align: start;
        height: 250px;
        overflow-y: auto;
        .list {
          font-size: 12px;
          font-weight: 400;
          margin-bottom: 10px;
        }
      }
      .checkname3 {
        display: grid;
        align-items: center;
        justify-content: space-between;
        grid-template-columns: repeat(2, 1fr);
        text-align: start;
        height: 100px;
        overflow-y: auto;
        .list {
          font-size: 12px;
          font-weight: 400;
          margin-bottom: 10px;
        }
      }
      .checkname6 {
        display: grid;
        align-items: center;
        justify-content: space-between;
        grid-template-columns: repeat(2, 1fr);
        text-align: start;
        height: 250px;
        overflow-y: auto;
        .list {
          font-size: 12px;
          font-weight: 400;
          margin-bottom: 10px;
        }
      }
      .checkname2 {
        display: grid;
        align-items: center;
        justify-content: space-between;
        .list2 {
          font-size: 12px;
          font-weight: 400;
          margin-bottom: 10px;
        }
      }
    }
    .colors {
      .color {
        font-size: 20px;
        font-weight: 600;
        text-align: start;
        border-top: 1px solid #998a85;
        padding: 10px 20px 0 20px;
      }
    }
  }
}
