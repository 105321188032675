.desc {
  .desc1 {
    font-size: 16px;
    font-weight: 500;
    text-align: start;
  }
  .desc2 {
    font-size: 16px;
    font-weight: 500;
    text-align: start;
  }
}
.line {
  width: 100%;
  border: 2px solid #e6e6e6;
  margin-top: 10px;
}
