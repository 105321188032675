.welMain {
  @media (max-width: 426px) {
    width: 100%;
  }
  @media (max-width: 321px) {
    width: 100%;
  }
  .setwallet {
    background-color: #e3e3e3;
    padding: 20px 50px;
    text-align: start;
    border-radius: 20px;
    .total {
      font-size: 20px;
      font-weight: 600;
    }
    .balance {
      font-size: 20px;
      font-weight: 600;
      .setimg {
        width: 30px;
        height: 30px;
      }
    }
  }
  .setdata {
    margin-bottom: 50px;
    .tran {
      font-size: 20px;
      font-weight: 600;
      margin-top: 30px;
      margin-bottom: 20px;
      text-align: start;
      padding: 0 50px;
    }
    .set {
      background-color: #e3e3e3;
      padding: 12px 50px;
      text-align: start;

      border-radius: 20px;
      .setting {
        display: flex;
        align-items: center;
        justify-content: space-between;
        > div {
          > p {
            font-size: 16px;
            font-weight: 500;
            width: 85%;
          }
        }
        .money {
          font-size: 16px;
          font-weight: 500;
          color: red;
          width: 10%;
        }
        .credit {
          font-size: 16px;
          font-weight: 500;
          color: green;
          width: 10%;
        }
      }
      .blank {
        border: 1px solid #adaeaf;
        opacity: 0.3;
      }
    }
  }
  .proced {
    background-color: #32140a;
    color: #fff;
    width: 100px;
    height: 40px;
   
  }
}
