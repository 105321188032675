.setwallet {
  background-color: #e3e3e3;
  padding: 20px 50px;
  text-align: start;
  border-radius: 20px;
  .total {
    font-size: 20px;
    font-weight: 600;
  }
  .balance {
    font-size: 20px;
    font-weight: 600;
  }
}
.welMain {
  @media (max-width: 426px) {
    width: 100%;
  }
  @media (max-width: 321px) {
    width: 100%;
  }
  > h2 {
    font-size: 24px;
  }
  .setdata {
    margin-bottom: 50px;
    .tran {
      font-size: 20px;
      font-weight: 600;
      margin-top: 30px;
      margin-bottom: 20px;
      text-align: start;
      padding: 0 50px;
    }
    .set {
      background-color: #e3e3e3;
      padding: 12px 50px;
      text-align: start;

      border-radius: 20px;
      .setting {
        display: flex;
        align-items: center;
        justify-content: space-between;
        > div {
          .first {
            font-size: 16px;
            font-weight: 700;
            width: 100%;
          }
          .second {
            font-size: 14px;
            font-weight: 500;
            width: 100%;
          }
        }
        .money {
          font-size: 16px;
          font-weight: 500;
          color: red;
        }
        .credit {
          font-size: 16px;
          font-weight: 500;
          color: green;
        }
      }
      .blank {
        border: 1px solid #adaeaf;
        opacity: 0.3;
      }
    }
  }
}
