@import "../../Assets/Sass/variable.scss";

.main {
  background-color: $primary;
  height: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  .user {
    height: 500px;
    background-color: $white;
    .setuser {
      padding: 10px 30px 10px 30px;
      text-align: start;
      //   .logo {
      //   }
      .login {
        font-size: 20px;
        font-weight: 700;
      }
      .phone {
        border: 1px solid $black;
        height: 40px;
      }
      .by {
        font-size: 16px;
        font-weight: 400;
        .use {
          color: palevioletred;
          cursor: pointer;
        }
        .policy {
          color: palevioletred;
          cursor: pointer;
        }
      }
      .continue {
        background-color: $black;
        color: $white;
        width: 100%;
        height: 40px;
      }
      .have {
        font-size: 16px;
        font-weight: 400;
        .help {
          color: palevioletred;
          cursor: pointer;
        }
      }
    }
  }
}
