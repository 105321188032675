@import "../../Assets/Sass/variable.scss";

.main {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @media (max-width: 426px) {
    width: 100%;
  }
  @media (max-width: 376px) {
    width: 100%;
  }
  @media (max-width: 321px) {
    width: 100%;
  }
  .shopping {
    position: relative;
    .cart {
      text-align: start;
      font-size: 20px;
      font-weight: 700;
    }
    .product {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 6%;
      padding-left: 9%;
      > p {
        font-size: 16px;
        font-weight: 500;
      }
      > div {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 90%;
        > p {
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
    .blank {
      border: 1px solid $black;
    }
    .setitem {
      padding-top: 22px;
      > div {
        box-shadow: 3px 2px 20px 0px #00000040;
        border-radius: 20px;
        .showItem {
          display: flex;
          align-items: center;
          justify-content: space-between;

          padding: 20px;
          @media (max-width: 321px) {
            padding: 10px !important;
          }
          .radio {
            margin-left: 10px;
            @media (max-width: 426px) {
              display: flex !important;
            }
            > span {
              > span {
                ::after {
                  background-color: $black;
                  border-color: $black;
                }
              }
            }
          }
          .add {
            display: flex;
            align-items: center;
            margin-right: 10%;
            > p {
              font-size: 16px;
              font-weight: 600;
              margin-top: 0;
            }
          }
          .tailor {
            width: 20%;
            text-align: start;
            @media (max-width: 426px) {
              width: 50%;
            }
            .arpit {
              font-size: 16px;
              font-weight: 500;
            }
            .no {
              font-size: 16px;
              font-weight: 500;
              margin-top: -10px;
            }
          }
          .address {
            font-size: 14px;
            font-weight: 500;
            width: 40%;
            text-align: start;
            @media (max-width: 426px) {
              display: none !important;
            }
          }

          .seticons {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 5%;
            @media (max-width: 426px) {
              width: 15%;
            }
            .delete {
              font-size: 20px;
              cursor: pointer;
            }
            .edit {
              font-size: 20px;
              cursor: pointer;
            }
          }
        }
        .address0 {
          display: none !important;
          @media (max-width: 426px) {
            display: grid !important;
            font-size: 14px;
            font-weight: 500;
            // width: 100%;
            text-align: start;
            padding: 18px;
            margin-top: -40px;
          }
        }
      }
    }
    .continue {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
      .addaddress {
        background-color: #32140a;
        color: $white;
        height: 45px;
        margin-top: 25px;
      }
    }
    .payment {
      > p {
        text-align: start;
        font-size: 20px;
        font-weight: 700;
      }
    }
    .method {
      display: flex;
      align-items: center;
      gap: 30px;
      margin-bottom: 80px;
      @media (max-width: 426px) {
        display: grid !important;
        grid-template-columns: repeat(1, 1fr) !important;
      }
      .choose {
        border: 1px solid $black;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 4px 20px 4px 20px;
        border-radius: 10px;
        gap: 10px;
        @media (max-width: 426px) {
          justify-content: start;
          // width: 57%;
        }
        .wallet {
          width: 20px;
          height: 20px;
        }
        .thousand {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }
  .main3 {
    flex: 1;
    margin-top: 30px;
    @media (max-width: 1024px) {
      margin-top: 0;
      margin-left: 25%;
    }
    @media (max-width: 768px) {
      margin-top: 0;
      margin-left: 17%;
    }
    @media (max-width: 426px) {
      margin-top: 0;
      margin-left: 0%;
    }
    > div {
      .order {
        border: 1px solid #adaeaf;
        box-shadow: 3px 2px 20px 0px #00000040;
        padding-left: 30px;
        padding-right: 30px;
        margin-bottom: 50px;
        @media (max-width: 321px) {
          padding-left: 15px;
          padding-right: 15px;
        }
        > p {
          font-size: 18px;
          font-weight: 600;
        }
        > div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 20px;
          @media (max-width: 376px) {
            gap: 7px;
          }
          > div {
            > img {
              width: 100px;
              height: 140px;
            }
          }
          .setside {
            margin: -10px -30px 0 0;
            .boota {
              font-size: 17px;
              font-weight: 500;
              width: 170px;
              text-align: start;
              @media (max-width: 376px) {
                font-size: 10px;
              }
            }
            .size {
              font-size: 16px;
              font-weight: 500;
              width: 170px;
              text-align: start;
              text-transform: capitalize;
              @media (max-width: 376px) {
                font-size: 10px;
              }
            }
          }
          .prices {
            display: flex;
            align-items: center;
            gap: 20px;
            // margin-top: -27%;
            @media (max-width: 426px) {
              display: grid;
              margin-top: 0%;
              gap: 10px;
            }
            @media (max-width: 376px) {
              display: grid;
              margin-top: 0%;
              gap: 10px;
              margin-left: -25px;
            }
            .price1 {
              font-size: 18px;
              font-weight: 600;
              margin: 0;
              @media (max-width: 321px) {
                font-size: 12px;
              }
            }
            .price2 {
              text-decoration: line-through;
              color: #998a85;
            }
          }
        }
        .delivery {
          border: 1px solid #adaeaf;
          border-radius: 15px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 20px;
          padding-left: 20px;
          > div {
            > img {
              width: 20px;
              height: 20px;
            }
          }
          .business {
            font-size: 14px;
            font-weight: 400;
          }
        }
        .code {
          border: 1px solid #adaeaf;
          border-radius: 15px;
          display: flex;
          align-items: center;
          justify-content: space-around;
          gap: 20px;
          @media (max-width: 426px) {
            gap: 0px !important;
            display: grid !important;
            justify-content: center !important;
            align-items: center !important;
          }
          @media (max-width: 376px) {
            border-radius: 7px;
          }
          .enter {
            .coding {
              border: 0;
              padding: 10px;
              @media (max-width:426px) {
                width: 300px !important;
              }
              @media (max-width:376px) {
                width: 200px !important;
              }
            }
            .blank4 {
              border: 1px solid #d5d5d5;
            }
            .explore {
              font-size: 15px;
              font-weight: 500;
              cursor: pointer;
            }
            .explore2 {
              font-size: 15px;
              font-weight: 500;
              // cursor: pointer;
              text-align: start;
              > span {
                font-size: 15px;
                font-weight: 500;
                cursor: pointer;
                text-align: start;
                margin-left: 10px;
                color: red;
              }
            }
            .explore3 {
              font-size: 15px;
              font-weight: 500;
              // cursor: pointer;
              text-align: start;
              > span {
                font-size: 15px;
                font-weight: 500;
                cursor: pointer;
                text-align: start;
                margin-left: 10px;
                color: red;
                margin: 0;
              }
            }
          }
          .enter2 {
            display: grid;
            align-items: center;
            .apply {
              background-color: #32140a;
              color: $white;
              height: 45px;
              width: 100px;
              @media (max-width:426px) {
                width: 300px !important;
              }
              @media (max-width:376px) {
                width: 200px !important;
              }
            }
            .arrow {
              width: 25px;
              height: 25px;
              cursor: pointer;
            }
          }
          .enter3 {
            display: grid;
            align-items: center;
            margin-top: -40px;
            .apply {
              background-color: #32140a;
              color: $white;
              height: 45px;
              width: 100px;
            }
            .arrow {
              width: 25px;
              height: 25px;
              cursor: pointer;
            }
          }
        }
        > div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          > p {
            font-size: 18px;
            font-weight: 600;
          }
          .pro {
            display: flex;
            align-items: center;
            margin-left: 13%;
            gap: 20px;
            .pro1 {
              font-size: 18px;
              font-weight: 600;
              margin: 0;
            }
            .pro2 {
              text-decoration: line-through;
              color: #998a85;
            }
          }
        }
        .coupon {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: -18px;
          .coupon1 {
            color: #3ca541;
            font-size: 18px;
            font-weight: 600;
          }
          .coupon2 {
            color: #3ca541;
            font-size: 18px;
            font-weight: 600;
          }
        }
        .blank3 {
          border: 1px solid #adaeaf;
          margin: 0;
        }
        .amount {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 0;
          margin-bottom: 20px;
          .amount1 {
            font-size: 18px;
            font-weight: 600;
          }
          .amount2 {
            font-size: 18px;
            font-weight: 600;
          }
        }
        .proced {
          background-color: #32140a;
          color: $white;
          width: 100%;
          height: 45px;
          margin-bottom: 25px;
        }
      }
    }
  }
}
.setmodal {
  > p {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    margin: 0;
  }
  .name {
    margin: 0;
    > div {
      > div {
        > div {
          > div {
            > input {
              border: 1px solid #999999;
              height: 40px;
              margin-top: 20px;
            }
            > textarea {
              border: 1px solid #999999;
              height: 60px;
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
  .select {
    margin: 0 0 8px 0;
    > div {
      > div {
        > div {
          > div {
            > div {
              > div {
                border: 1px solid #999999 !important;
                height: 40px !important;
                margin-top: 20px !important;
                border-radius: 7px !important;
              }
              > span {
                margin-top: 18px !important;
              }
            }
          }
        }
      }
    }
  }
  .home {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 25px;
    @media (max-width: 426px) {
      display: flex !important;
      align-items: center;
      justify-content: center;
      width: 100%;
      gap: 10px;
    }
    @media (max-width: 376px) {
      display: contents !important;
      align-items: center !important;
      justify-content: center !important;
      width: 100% !important;
      // gap: 20px !important;
      // margin-top: 20px !important;
    }
    .homebtn1 {
      margin: 0;
      .homebtn {
        width: 100%;
        height: 38px;
        background-color: $black;
        color: $white;
        @media (max-width: 376px) {
          margin-top: 20px;
        }
      }
      .homebtn2 {
        width: 100%;
        height: 38px;
        background-color: #ebe8e7;
        color: $black;
        border: 1px solid $black;
        @media (max-width: 376px) {
          margin-top: 20px !important;
        }
      }
    }
  }
  .mainbtn {
    .save {
      width: 100%;
      height: 35px;
      background-color: $black;
      color: $white;
      margin-top: 20px;
    }
  }
}

.setcoupon {
  .settext {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    margin: 0;
    margin-bottom: 20px;
  }
  .couponset {
    border-radius: 10px;
    padding: 8px 20px 1px 20px;
    box-shadow: 3px 2px 20px 0px #00000040;
    margin-bottom: 20px;
    .firstset {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .for {
        font-size: 16px;
        font-weight: 500;
        color: $black;
        border: 2px dashed;
        background-color: #ebe8e7;
        padding: 5px 30px;
      }
      .app {
        background-color: #32140a;
        color: $white;
        width: 100px;
        height: 40px;
      }
    }
    .blanking {
      border: 1px solid #d5d5d5;
    }
    .discou {
      font-size: 16px;
      font-weight: 500;
    }
  }
}
