@import "../../Assets/Sass/variable.scss";

.main {
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 426px) {
    width: 100% !important;
  }
  @media (max-width: 376px) {
    width: 100% !important;
  }
  @media (max-width: 321px) {
    width: 100% !important;
  }
  .shopping {
    .cart {
      text-align: start;
      font-size: 20px;
      font-weight: 700;
    }
    .product {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 6%;
      padding-left: 9%;
      @media (max-width: 321px) {
        padding-right: 0%;
        padding-left: 0%;
      }
      > p {
        font-size: 16px;
        font-weight: 500;
      }
      > div {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 80%;
        @media (max-width: 426px) {
          width: 33% !important;
        }
        > p {
          font-size: 16px;
          font-weight: 500;
          margin-left: -172px;
          @media (max-width: 426px) {
            margin-left: -156px;
          }
          @media (max-width: 376px) {
            margin-left: -98px;
          }
        }
        .p2 {
          font-size: 16px;
          font-weight: 500;
          margin-left: -172px;
          @media (max-width: 426px) {
            margin-left: -68px !important;
          }
          @media (max-width: 376px) {
            margin-left: 32px !important;
          }
        }
      }
    }
    .blank {
      border: 1px solid $black;
    }
    .setitem {
      padding-top: 22px;
      > div {
        box-shadow: 3px 2px 20px 0px #00000040;
        border-radius: 20px;
        .showItem {
          display: flex;
          align-items: center;
          justify-content: space-between;

          padding: 20px;
          @media (max-width: 321px) {
            padding: 10px !important;
          }
          .radio {
            margin-left: 10px;
            @media (max-width: 426px) {
              display: flex !important;
            }
            > span {
              > span {
                ::after {
                  background-color: $black;
                  border-color: $black;
                }
              }
            }
          }
          .add {
            display: flex;
            align-items: center;
            margin-right: 10%;
            > p {
              font-size: 16px;
              font-weight: 600;
              margin-top: 0;
            }
          }
          .tailor {
            width: 20%;
            text-align: start;
            @media (max-width: 426px) {
              width: 50%;
            }
            .arpit {
              font-size: 16px;
              font-weight: 500;
            }
            .no {
              font-size: 16px;
              font-weight: 500;
              margin-top: -10px;
            }
          }
          .address {
            font-size: 14px;
            font-weight: 500;
            width: 40%;
            text-align: start;
            @media (max-width: 426px) {
              display: none !important;
            }
          }

          .seticons {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 5%;
            @media (max-width: 426px) {
              width: 15%;
            }
            .delete {
              font-size: 20px;
              cursor: pointer;
            }
            .edit {
              font-size: 20px;
              cursor: pointer;
            }
          }
        }
        .address0 {
          display: none !important;
          @media (max-width: 426px) {
            display: grid !important;
            font-size: 14px;
            font-weight: 500;
            // width: 100%;
            text-align: start;
            padding: 18px;
            margin-top: -40px;
          }
        }
      }
    }
    .continue {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
      .addaddress {
        background-color: #32140a;
        color: $white;
        height: 45px;
        margin-top: 25px;
      }
    }
  }
}
.setmodal {
  > p {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    margin: 0;
  }
  .name {
    margin: 0;
    > div {
      > div {
        > div {
          > div {
            > input {
              border: 1px solid #999999;
              height: 40px;
              margin-top: 20px;
            }
            > textarea {
              border: 1px solid #999999;
              height: 60px;
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
  .select {
    margin: 0 0 8px 0;
    > div {
      > div {
        > div {
          > div {
            > div {
              > div {
                border: 1px solid #999999 !important;
                height: 40px !important;
                margin-top: 20px !important;
                border-radius: 7px !important;
              }
              > span {
                margin-top: 18px !important;
              }
            }
          }
        }
      }
    }
  }
  .home {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 25px;
    .homebtn1 {
      margin: 0;
      .homebtn {
        width: 100%;
        height: 38px;
        background-color: $black;
        color: $white;
      }
      .homebtn2 {
        width: 100%;
        height: 38px;
        background-color: #ebe8e7;
        color: $black;
        border: 1px solid $black;
      }
    }
  }
  .mainbtn {
    .save {
      width: 100%;
      height: 35px;
      background-color: $black;
      color: $white;
      margin-top: 20px;
    }
  }
}
